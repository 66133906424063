@font-face {
  font-family: 'globerheavy';
  src: url("../fonts/glober/fontfabric_-_globerheavy-webfont.woff2") format("woff2"), url("../fonts/glober/fontfabric_-_globerheavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'globerregular';
  src: url("../fonts/glober/fontfabric_-_globerregular-webfont.woff2") format("woff2"), url("../fonts/glober/fontfabric_-_globerregular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'globersemibold';
  src: url("../fonts/glober/fontfabric_-_globersemibold-webfont.woff2") format("woff2"), url("../fonts/glober/fontfabric_-_globersemibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

*, *:before, *:after {
  box-sizing: border-box; }

html {
  font-size: 1vw; }

body {
  font-family: 'globerregular', sans-serif;
  padding: 0;
  color: #333333;
  margin: 0; }

header {
  padding: 2vh 3vw;
  background-color: #eeeeee;
  clear: both; }
  header img {
    width: 7vw; }
  header .info {
    float: right;
    display: inline-block;
    text-align: right; }
    header .info h3 {
      margin: 0;
      font-family: 'globerheavy';
      font-size: 1.3rem; }
    header .info a {
      color: #333333;
      font-family: globersemibold;
      text-decoration: none;
      font-size: 1.1rem; }

.fechaActual {
  padding: 1vh 3vw;
  font-family: globerheavy;
  font-size: 1.5rem;
  clear: both; }
  .fechaActual #fecha {
    display: inline-block; }
  .fechaActual #hora {
    display: inline-block;
    float: right; }

.contColumnas {
  padding: 2vh 3vw;
  display: flex;
  justify-content: space-between; }
  .contColumnas .columna {
    width: 23%;
    transition: 300ms;
    background-color: #eeeeee;
    border-top: 7px solid #e11233;
    padding: 1vh 1vw;
    height: 75vh;
    overflow: auto; }
    .contColumnas .columna .contPedidos {
      width: 100%;
      overflow: auto;
      max-height: 65vh; }
      .contColumnas .columna .contPedidos .pedido {
        padding: 2vh;
        background-color: #dcdcdc;
        border-radius: 10px;
        position: relative;
        margin-bottom: 2vh; }
        .contColumnas .columna .contPedidos .pedido h5 {
          margin: 0;
          font-size: .9rem; }
          .contColumnas .columna .contPedidos .pedido h5 .tituloFila {
            display: none;
            font-family: globerheavy; }
        .contColumnas .columna .contPedidos .pedido .acciones {
          text-align: right;
          border-top: 1px solid #888888; }
          .contColumnas .columna .contPedidos .pedido .acciones a {
            display: inline-block;
            margin: 2vh .5vw 0;
            color: #333333;
            font-family: globersemibold;
            font-size: .85rem; }
        .contColumnas .columna .contPedidos .pedido .alert {
          display: none;
          position: absolute;
          top: 2vh;
          right: 2vh; }
        .contColumnas .columna .contPedidos .pedido.cambio .alert {
          display: inline-block; }
    .contColumnas .columna .tituloCol {
      font-family: globerheavy;
      margin: 0 0 1vh; }
    .contColumnas .columna#alistamiento {
      border-top-color: #f3a312; }
    .contColumnas .columna#enCamino {
      border-top-color: #2d3490; }
    .contColumnas .columna#entregados {
      border-top-color: #479c26; }
    .contColumnas .columna#demorados {
      border-top-color: #2742ef; }
    .contColumnas .columna:hover {
      width: 28%; }
      .contColumnas .columna:hover .pedido h5 span {
        overflow: hidden; }
      .contColumnas .columna:hover .pedido h5 .tituloFila {
        display: initial; }

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(150, 150, 150, 0.7);
  overflow: auto;
  display: none; }

.modalResumen .modalInterno {
  width: 60vw;
  top: 50%;
  left: 50%;
  margin: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
  background-color: white;
  border-radius: 10px; }
  .modalResumen .modalInterno .cabezaModal {
    background-color: #e11233;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    padding: 2vh 2vh 2vh; }
    .modalResumen .modalInterno .cabezaModal i {
      font-size: 2rem; }
    .modalResumen .modalInterno .cabezaModal a {
      flex-grow: 1;
      text-align: center;
      color: white;
      text-decoration: none; }
    .modalResumen .modalInterno .cabezaModal .titulo {
      text-align: center;
      flex-grow: 2;
      color: white; }
      .modalResumen .modalInterno .cabezaModal .titulo .alert {
        display: none; }
  .modalResumen .modalInterno .logo {
    width: 30%;
    display: block;
    margin: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-bottom: -5vh; }
  .modalResumen .modalInterno .infoPedido h5 {
    margin: 0;
    padding: 0 5vh;
    clear: both; }
    .modalResumen .modalInterno .infoPedido h5 span {
      float: right;
      font-size: 1.4rem; }
      .modalResumen .modalInterno .infoPedido h5 span.tituloFila {
        float: none; }
  .modalResumen .modalInterno .resumenPedido {
    padding: 2vh 5vh;
    overflow: auto; }
    .modalResumen .modalInterno .resumenPedido .elemento {
      margin-bottom: 3px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .modalResumen .modalInterno .resumenPedido .elemento img {
        max-width: 70px;
        max-height: 54px;
        background-color: #888888;
        flex: 1;
        text-align: center; }
        .modalResumen .modalInterno .resumenPedido .titulo{
          flex: 10;
          text-align: center;
        }
        .modalResumen .modalInterno .resumenPedido .cantidad{
          flex: 1;
          text-align: center;
        }
        .modalResumen .modalInterno .resumenPedido .precio{
          flex: 1;
          text-align: center;
        }
      .modalResumen .modalInterno .resumenPedido .elemento .titulo h3 {
        font-size: 1rem;
        margin: 0; }
      .modalResumen .modalInterno .resumenPedido .elemento .titulo h4 {
        margin: 0;
        font-size: 1.3rem; }
      .modalResumen .modalInterno .resumenPedido .elemento .cantidad span {
        width: 3vw;
        padding: 0 0;
        border-radius: 5px;
        display: inline-block;
        font-size: 0.8rem;
        text-align: center;
        border: 1px solid black; }
      .modalResumen .modalInterno .resumenPedido .elemento .precio span {
        font-size: 1.2rem; }
  .modalResumen .modalInterno .preciosTotales {
    display: flex;
    padding: 2vh 5vh;
    justify-content: space-between; }
    .modalResumen .modalInterno .preciosTotales span {
      font-size: 1.5rem; }

.modalCancelar .modalInterno {
  width: 60vw;
  top: 50%;
  left: 50%;
  margin: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
  background-color: white;
  border-radius: 10px; }
  .modalCancelar .modalInterno .cabezaModal {
    background-color: #e11233;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    padding: 2vh 2vh 9vh; }
    .modalCancelar .modalInterno .cabezaModal i {
      font-size: 2rem; }
    .modalCancelar .modalInterno .cabezaModal a {
      flex-grow: 1;
      text-align: center;
      color: white;
      text-decoration: none; }
      .modalCancelar .modalInterno .cabezaModal a.printModal {
        pointer-events: none;
        visibility: hidden; }
    .modalCancelar .modalInterno .cabezaModal .titulo {
      text-align: center;
      flex-grow: 2;
      color: white; }
      .modalCancelar .modalInterno .cabezaModal .titulo .alert {
        display: none; }
  .modalCancelar .modalInterno .logo {
    width: 30%;
    display: block;
    margin: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-bottom: -5vh; }
  .modalCancelar .modalInterno h2 {
    text-align: center;
    margin-bottom: 2vh; }
  .modalCancelar .modalInterno form {
    padding-bottom: 3vh; }
    .modalCancelar .modalInterno form input {
      display: none; }
      .modalCancelar .modalInterno form input:checked + label {
        background-color: #e21c2a;
        border-color: #e21c2a;
        color: white; }
    .modalCancelar .modalInterno form label {
      display: block;
      margin: auto;
      width: 50%;
      text-align: center;
      padding: 2vh 0;
      margin-bottom: 2%;
      border: 1px solid #333333;
      border-radius: 10px;
      color: #333333;
      font-family: globersemibold; }
      .modalCancelar .modalInterno form label:hover {
        background-color: #e21c2a;
        border-color: #e21c2a;
        color: white; }
    .modalCancelar .modalInterno form textarea {
      width: 50%;
      margin: auto;
      display: block;
      resize: none;
      height: 15vh;
      margin-bottom: 5vh;
      border: 1px solid #333333;
      border-radius: 10px; }
    .modalCancelar .modalInterno form .wrap-login100-form-btn {
      width: 50%; }

.modalConfCancelado {
  /*display: block; */
}
  .modalConfCancelado .closeModal {
    pointer-events: none;
    visibility: hidden; }

.modalCerrarSesion .modalInterno, .modalConfCancelado .modalInterno {
  width: 60vw;
  top: 50%;
  left: 50%;
  margin: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
  background-color: white;
  border-radius: 10px; }
  .modalCerrarSesion .modalInterno .cabezaModal, .modalConfCancelado .modalInterno .cabezaModal {
    background-color: #e11233;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    padding: 2vh 2vh 9vh; }
    .modalCerrarSesion .modalInterno .cabezaModal i, .modalConfCancelado .modalInterno .cabezaModal i {
      font-size: 2rem; }
    .modalCerrarSesion .modalInterno .cabezaModal a, .modalConfCancelado .modalInterno .cabezaModal a {
      flex-grow: 1;
      text-align: center;
      color: white;
      text-decoration: none; }
      .modalCerrarSesion .modalInterno .cabezaModal a.printModal, .modalConfCancelado .modalInterno .cabezaModal a.printModal {
        pointer-events: none;
        visibility: hidden; }
    .modalCerrarSesion .modalInterno .cabezaModal .titulo, .modalConfCancelado .modalInterno .cabezaModal .titulo {
      text-align: center;
      flex-grow: 2;
      color: white; }
      .modalCerrarSesion .modalInterno .cabezaModal .titulo .alert, .modalConfCancelado .modalInterno .cabezaModal .titulo .alert {
        display: none; }
  .modalCerrarSesion .modalInterno .logo, .modalConfCancelado .modalInterno .logo {
    width: 30%;
    display: block;
    margin: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-bottom: -5vh; }
  .modalCerrarSesion .modalInterno h2, .modalConfCancelado .modalInterno h2 {
    text-align: center;
    margin-bottom: 2vh; }
  .modalCerrarSesion .modalInterno .buttons, .modalConfCancelado .modalInterno .buttons {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5vh; }
  .modalCerrarSesion .modalInterno .wrap-login100-form-btn, .modalConfCancelado .modalInterno .wrap-login100-form-btn {
    width: 40%; }
.closeModal{
  color: white;
}


@media print {
  .modalResumen .modalInterno .cabezaModal {
    display: none;
  }
  .modalResumen .modalInterno .resumenPedido .elemento img {
    flex: 0;
    display: none;
  }
  .modalResumen .modalInterno .resumenPedido .elemento .cantidad span {
    border-radius: 0;
    font-size: 9pt;
    border: none;
  }
  .modalResumen .modalInterno .resumenPedido .titulo{
    text-align: left;
  }
 }
/* You can add global styles to this file, and also import other style files */
@import url("assets/vendor/bootstrap/css/bootstrap.min.css");
@import url("assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css");
@import url("assets/fonts/iconic/css/material-design-iconic-font.min.css");
@import url("assets/vendor/animate/animate.css");
@import url("assets/vendor/css-hamburgers/hamburgers.min.css");
@import url("assets/vendor/animsition/css/animsition.min.css");
@import url("assets/vendor/select2/select2.min.css");
@import url("assets/vendor/daterangepicker/daterangepicker.css");
@import url("assets/css/util.css");
@import url("assets/css/main.css");
@import url("assets/css/style.css");
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");

.overlayYes{
  position: relative;
  &::before{
    position: absolute;
    content: "+";
    font-size: 8vw;
    color: lightgreen;
    border: 3px solid lightgreen;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0, .25);
    z-index: 10;
    text-align: center;
    padding: 50% 40%;

  }
}
.overlayNo{
  position: relative;
  &::before{
    position: absolute;
    content: "x";
    font-size: 8vw;
    color: orangered;
    border: 3px solid orangered;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0, .25);
    z-index: 10;
    text-align: center;
    padding: 50% 40%;

  }
}

